













import Vue from "vue";
export default Vue.extend({
  data: () => ({
    multiLine: true,
    snackbar: false,
    text: "",
  }),
  methods: {
    show(text: string | string[]) {
      var msg = "";
      if (Array.isArray(text)) {
        for (const message of text) {
          msg += `*${message} <br>`;
        }
      } else {
        msg = text;
      }
      this.text = msg;
      this.snackbar = true;
    },
  },
});
