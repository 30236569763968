


















import Vue from "vue";
export default Vue.extend({
  name: "the-loader",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
