





















































import { GetResponseErrors } from "@/common/utils";
import TheLoader from "@/components/TheLoader.vue";
import TheSnackbar from "@/components/TheSnackbar.vue";
import Vue from "vue";
import ThePlaceholder from "@/components/ThePlaceholder.vue";
import { mapGetters } from "vuex";
import { GetNotifications } from "@/api/notifications";
import { Notification } from "@/models/interfaces/Notification";
import { NotificationTypes } from "@/models/enums/NotificationTypes";

export default Vue.extend({
  name: "notifications",
  metaInfo: {
    title: "Mis notificaciones",
  },
  components: { TheLoader, TheSnackbar, ThePlaceholder },
  watch: {
    currentPage(oldVal, newVal) {
      if (oldVal != newVal && !this.isLoading) {
        this.fetchNotifications();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      isLoading: false,
      notifications: [] as Notification[],
      currentPage: 1,
      lastPage: 1,
    };
  },
  mounted() {
    this.fetchNotifications();
  },
  methods: {
    getNotificationIcon(notification: Notification) {
      switch (notification.data.target) {
        case NotificationTypes.WinnerNotification:
          if (notification.data.ref.toString() != "") {
            return require("@/assets/icons/ic_notification_multiple_play.png");
          }
          return require("@/assets/icons/ic_winner_ticket_notification.png");
        case NotificationTypes.NewRechargeNotification:
          return require("@/assets/icons/ic_wallet.png");
        case NotificationTypes.AwardPaymentNotification:
          return require("@/assets/icons/ic_wallet.png");
        case NotificationTypes.AutorechargeNotification:
          return require("@/assets/icons/ic_autorecharge.png");
        case NotificationTypes.WithdrawalFinished:
          return require("@/assets/icons/ic_withdraw_icon.png");
      }
      return require("@/assets/logo.png");
    },
    async fetchNotifications() {
      this.isLoading = true;
      try {
        const res = await GetNotifications(this.currentPage);
        if (res.data.data) {
          this.notifications = res.data.data;
        }
        this.currentPage = res.data.current_page;
        this.lastPage = res.data.last_page;
      } catch (err: any) {
        if (err.response.status != 404) {
          (this.$refs.snackbar as any).show(GetResponseErrors(err));
        }
      }
      this.isLoading = false;
    },
  },
});
